<template>
    <div>
        <v-data-table
            v-model="value"
            :item-key="props.key"
            :show-select="props.showSelect"
            :key="props.keyValue" 
            disable-pagination 
            hide-default-footer 
            :loading="props.loading" 
            :class="`elevation-0 ${props.loading ? '': 'table-b-border-item'}`" 
            no-results-text="No hay resultados" no-data-text="No hay datos" 
            :headers="props.headers" 
            checkboxColor="primary"
            :items="items" 
            :options="options" >
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                    <template v-slot:activator="{ on }">
                        <v-btn x-small v-if="action.type === 'method'" class="mx-0" icon @click="methodHandler(action.action, item.parameters)" :disabled="action.disabled">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else-if="action.type === 'redirect'" icon text :to="action.action" :disabled="action.disabled">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                        <v-btn x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.action" :disabled="action.disabled">
                            <v-icon :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ action.name }}</span>
                </v-tooltip>
            </template>
            <template
                v-for="slot in Object.keys($scopedSlots)"
                :slot="slot"
                slot-scope="scope"
                ><slot :name="slot" v-bind="scope"
            /></template>
        </v-data-table>

    </div>
</template>

<script>
    export default {
    
        name: 'datatable',
        props: ['props', 'items', 'valueInput', 'opts'],
        model: {
            prop: 'value',
            event: 'valueChange'
        },
        computed: {
            value: {
                get: function() {
                    return this.valueInput
                },
                set: function(value) {
                    console.log(value)
                    this.$emit('valueChange', value)
                }
            },
            options: {
                get: function() {
                    return this.opts
                },
                set: function(value) {
                    console.log(value, "options")
                    this.$emit('optionsChange', value)
                }
            }
        },
        methods: {
            methodHandler(methodName, parameters){
                
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            
        }
    }
</script>

<style>

</style>