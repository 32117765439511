
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class VacationService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get areas
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/vacations`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET EDIT/SHOW
     * @param {string} id item id
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/vacations/${id}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * 
     * @param {object} data data
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/vacations`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} id item id
     * @param {object} data data
     */
    async update(id,data)
    {
        return this.http.put(`${this.baseUrl}/vacations/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * 
     * @param {string} id 
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/vacations/${id}`)
        .catch(this.handleErrorResponse);
    }


    async getVacationsByEmployee(id)
    {
        return this.http.get(`${this.baseUrl}/vacations/employee/${id}`)
        .catch(this.handleErrorResponse);
    }

    async getVacationEmployeeDetails(employee_id, numYear)
    {
        return this.http.get(`${this.baseUrl}/vacations/employee/${employee_id}/num_year/${numYear}`)
        .catch(this.handleErrorResponse);
    }


    async rejectRequest(id, data)
    {
        return this.http.put(`${this.baseUrl}/vacations/${id}/decline`, data)
        .catch(this.handleErrorResponse);
    }

    async authorizeRequest(id, data)
    {
        return this.http.put(`${this.baseUrl}/vacations/${id}/authorized`, data)
        .catch(this.handleErrorResponse);
    }

    async acceptRequest(id, data)
    {
        return this.http.put(`${this.baseUrl}/vacations/${id}/accept`, data)
        .catch(this.handleErrorResponse);
    }

}